import React from "react";

export const About = ({ data = {} }) => {
  const { title, paragraph1, paragraph2, paragraph3, paragraph4, image } = data;
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 ">
            <div className="about-img">
              <img src={image} className="img-responsive" alt="" />
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{title}</h2>
              <div className="hr"><hr /></div>
              <p>{paragraph1}</p>
              <p>{paragraph2}</p>
              <p>{paragraph3}</p>
              <p>{paragraph4}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
