import React from "react";

export const Header = ({ data = {} }) => {
  const { title, subTitle, button } = data;
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="intro-text">
                <h1>{title}</h1>
                <p>{subTitle}</p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  {button}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
