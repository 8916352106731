import { useState } from "react";
import { Image } from "./image";
import React from "react";
import { useEffect } from "react";
import FsLightbox from "fslightbox-react";

export const Gallery = ({ data = {} }) => {
  const [imagesArray, setImagesArray] = useState([]);

  const [toggler, setToggler] = useState(0);

  const [types, setTypes] = useState(typesArray);
  const { images, title } = data;
  const [showAllFilterItems, setShowAllFilterItems] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    number: 0,
  });

  useEffect(() => {
    setImagesArray(images?.map((img, index) => ({ ...img, id: index + 1 })));
  }, [images]);

  const handleClick = (value) => {
    setShowAllFilterItems(true);
    value === "*"
      ? setImagesArray(images.map((img, index) => ({ ...img, id: index + 1 })))
      : setImagesArray(
          images
            .filter(({ type }) => type === value)
            .map((img, index) => ({ ...img, id: index + 1 }))
        );
    setTypes(
      types
        .map((type) => ({ ...type, active: false }))
        .map((type) => (type.type === value ? { ...type, active: true } : type))
    );
  };

  useEffect(() => {
    setLightboxController({
      toggler: toggler !== 0,
      number: toggler,
    });
  }, [toggler]);

  return (
    <>
      {toggler !== 0 && (
        <FsLightbox
          openOnMount={true}
          onClose={(e) => setToggler(0)}
          slide={lightboxController.number}
          sources={imagesArray?.map(({ largeImage }) => largeImage)}
        />
      )}

      <div id="portfolio">
        <div className="section-title text-center center">
          <div className="overlay">
            <h2>{title}</h2>
            <hr />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="categories">
              <ul className="cat">
                <li>
                  <ol className="type">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    {types.map(({ type, active, label }, index) => (
                      <li key={index}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          data-filter="*"
                          onClick={() => handleClick(type)}
                          className={active ? "active" : ""}
                        >
                          {label}
                        </a>
                      </li>
                    ))}
                  </ol>
                </li>
              </ul>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {imagesArray?.slice(0, 15).map((img, index) => (
                <div
                  key={index}
                  className={`col-sm-6 col-md-4 col-lg-4 ${img.type}`}
                  onClick={() => setToggler(img.id)}
                >
                  <Image {...img} />
                </div>
              ))}

              {showAllFilterItems &&
                imagesArray?.slice(15).map((img, index) => (
                  <div
                    key={index}
                    className={`col-sm-6 col-md-4 col-lg-4 ${img.type}`}
                    onClick={() => setToggler(img.id)}
                  >
                    <Image {...img} />
                  </div>
                ))}
            </div>
            {!showAllFilterItems && (
              <div className="container">
                <div className="row">
                  <div className="intro-text  text-center center">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className="btn btn-custom btn-lg page-scroll"
                      onClick={() => setShowAllFilterItems(true)}
                    >
                      Mostrar Mais
                    </a>{" "}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row" style={{ marginTop: "40px" }}>
            <div className="portfolio-items">
              <div className={`col-sm-10 col-sm-offset-1`}>
                <div className="portfolio-item">
                  <div className="hover-bg video">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a title={title} data-lightbox-gallery="gallery1">
                     
                      <video width="100%" height="auto" controls>
                        <source src="video/Maraoavista.mp4" type="video/mp4" />
                      </video>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container"></div>
      </div>
    </>
  );
};

const typesArray = [
  {
    type: "*",
    label: "Todos",
    active: true,
  },
  {
    type: "primavera",
    label: "primavera",
    active: false,
  },
  {
    type: "verao",
    label: "verao",
    active: false,
  },
  {
    type: "outono",
    label: "outono",
    active: false,
  },
  {
    type: "inverno",
    label: "inverno",
    active: false,
  },
  {
    type: "quatro-estacoes",
    label: "quatro-estacoes",
    active: false,
  },
  {
    type: "wc",
    label: "wc Comum",
    active: false,
  },
  {
    type: "area-comum",
    label: "Area Comum",
    active: false,
  },
];
