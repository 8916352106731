import React from "react";

export const Contact = (props) => {
  return (
    <>
      <div id="call-reservation" className="text-center">
        <div className="container">
          <div className="section-title text-center">
            <h2>
              Quer fazer reserva? Ligue para <strong>+351 969006414</strong> ou{" "}
              <strong>+351 967915187</strong>
            </h2>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <div className="col-md-4">
            <h3>Morada</h3>
            <div className="contact-item">
              <p>Rua da Mó 213 </p>
              <p>Poiares - Peso da Régua, 5050-346</p>
            </div>
          </div>
          <div className="col-md-4">
            <h3>Check-out / Check-In</h3>
            <div className="contact-item">
              <p> Check-out: 9:00 - 12:00 </p>
              <p>Check-In: 15:00 - 21:00 </p>
            </div>
          </div>
          <div className="col-md-4">
            <h3>Contactos </h3>
            <div className="contact-item">
              <p>Phone: +351 969006414</p>
              <p>Phone: +351 967915187</p>
              <p>Email: maraoavista@sapo.pt</p>
            </div>
          </div>
        </div>
        <div className="container-fluid text-center copyrights">
          <div className="col-md-8 col-md-offset-2">
            <div className="social">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/people/Al-Mar%C3%A3o-%C3%A1-Vista/61553412693960/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
              </ul>
            </div>
            <p>&copy; 2023 JFerreira Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </>
  );
};
