import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

export const Team = (props) => {
  const [toggler, setToggler] = useState(false);
  return (
    <div id="team" className="text-center">
      <FsLightbox
        toggler={toggler}
        onClose={(e) => {
          setToggler(true);
        }}
        sources={["img/miradouro/SLeonardo.jpg", "img/miradouro/Formiga.jpeg"]}
      />

      <div className="overlay">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Pontos de interesse</h2>
            <hr />
          </div>
          <div id="row">
            <div className="col-md-4 col-md-offset-2 team">
              <div className="thumbnail">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  onClick={() => setToggler(true)}
                  title="Miradouro de São Leonardo de Galafura"
                  data-lightbox-gallery="gallery2"
                >
                  <div className="team-img">
                    <img
                      src="img/miradouro/SLeonardo_Easy-Resize.com.jpg"
                      alt="..."
                    />
                  </div>
                  <div className="caption">
                    <h3>Miradouro de São Leonardo de Galafura</h3>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4  team">
              <div className="thumbnail">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  onClick={() => setToggler(true)}
                  title="Miradouro da Formiga"
                  data-lightbox-gallery="gallery2"
                >
                  <div className="team-img">
                    <img
                      src="img/miradouro/Formiga_Easy-Resize.com.jpg"
                      alt="..."
                    />
                  </div>
                  <div className="caption">
                    <h3>Miradouro da Formiga</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
